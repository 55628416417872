<template>
  <a-spin :spinning="page_loading">
    <sdPageHeader title="Hediye Kredi Yükle (T-Soft Kargo)">
      <template #buttons>
        <div class="page-header-actions" />
      </template>
    </sdPageHeader>
    <Main>
      <sdCards title="Kredi Yükleme Formu">
        <a-form
          layout="vertical"
        >
          <a-form-item label="Müşteri Seçiniz">
            <a-select
              v-model:value="form_state.customer_ids"
              mode="multiple"
              placeholder="Müşteri Seçiniz"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in customers"
                :key="item.id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="Bakiye Giriniz"
          >
            <a-input v-model:value="form_state.amount" />
          </a-form-item>
          <a-form-item
            name="Not"
            label="Not"
          >
            <a-textarea
              v-model:value="form_state.note"
              :rows="4"
              placeholder="Not Giriniz"
            />
          </a-form-item>
          <sdButton
            size="small"
            type="primary"
            @click="handleAddCredit"
          >
            <sdFeatherIcons
              type="save"
              size="14"
            />
            <span>Kaydet</span>
          </sdButton>
        </a-form>
      </sdCards>
    </Main>
  </a-spin>
</template>

<script>

import { Main } from '../styled'

export default {
  name: 'TsoftKargoAddCredit',
  components: {
    Main,
  },
  data() {
    return {
      page_loading: false,
      form_submiting: false,

      accounts: [],
      customers: [],

      form_state: {
        customer_ids: [],
        amount: 0,
        note: null,
      },
    }
  },
  mounted() {
    this.page_loading = true
    this.$restMethods.postForNoc('/tsoft-kargo-add-credit-open').then(response => {
      if ( ! response.hasError()) {
        this.customers = response.getData().customers
      }
      this.page_loading = false
    })
  },
  methods: {
    handleAddCredit() {
      this.form_submiting = true
      this.$restMethods.postForNoc('/tsoft-kargo-add-credit-submit', this.form_state, { show_success: true }).then(response => {
        this.form_submiting = false
      })
    },
  },
}
</script>
